<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <template slot="footer">
        <a-button
          v-if="config.type === 'look'"
          key="back"
          @click="handleCancel"
        >
          取消
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk"
          v-if="config.type === 'look'"
        >
          下一步
        </a-button>
        <a-button
          v-if="config.type === 'password'"
          key="back"
          type="primary"
          @click="handleCancel"
        >
          关闭
        </a-button>
      </template>
      <div style="height: 100px" v-if="config.type === 'look'">
        <div class="top_text top">请输入登录密码后点击下一步</div>
        <div class="top_text">
          <a-input placeholder="请输入" v-model="password" />
        </div>
        <transition name="top-fade">
          <div v-if="error" class="top_text notice">
            密码不正确，请重新输入!
          </div>
          <div v-if="empty" class="top_text notice">密码不能为空!</div>
        </transition>
      </div>
      <div v-if="config.type === 'password'">
        <div class="text_contain">密码：{{ config.password }}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { sassInfoApi } from "@/api/sassInfo.js";
import pagination from "@/mixins/Pagination";

export default {
  mixins: [pagination],
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
          password: "",
        };
      },
    },
  },
  data() {
    //校验手机号
    // let validatorPhone = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("手机号码不能为空"));
    //   } else if (!/^1[3456789]\d{9}$/.test(value) && value) {
    //     callback(new Error("手机号码格式不正确"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      show: true,
      loading: false,
      confirmLoading: false,
      password: "",
      empty: false,
      error: false,
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
    };
  },
  mounted() {
    // this.inintModal();
  },
  created() {},
  watch: {},
  methods: {
    inintModal() {
      if (this.config.type === "look") {
        return;
      }
    },

    handleCancel() {
      this.$emit("handleCancel");
    },
    //校验密码
    checkPassword() {
      // this.$emit("handleOk");
      this.confirmLoading = true;
      let params = {
        password: this.password,
        id: this.config.record.resourceId,
      };
      sassInfoApi.checkPassword(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          if (res.data.checkResult) {
            this.error = false;

            this.$emit("handleOk", res.data.password);
          } else {
            this.error = true;
          }
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },

    handleOk() {
      if (this.config.type === "look") {
        if (this.password) {
          this.checkPassword();
          this.empty = false;
        } else {
          this.empty = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input_width {
  width: 400px;
}

.top_text {
  width: 400px;
  margin: auto;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 900;
  // background-color: aquamarine;
}
.notice {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: red;
  // background-color: aquamarine;
}
.top {
  margin-bottom: 10px;
}
.text_contain {
  height: 100px;
  line-height: 100px;
  width: 400px;
  margin: auto;
  font-size: 18px;
  // font-weight: 900;
  // background-color: aquamarine;
}
.test {
  background-color: yellow;
}
.top-fade-enter, .top-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.top-fade-enter-active,
.top-fade-leave-active {
  transition: opacity 1s;
}
</style>
