<template>
  <a-select
    :show-arrow="false"
    show-search
    :value="selectedVal"
    :placeholder="config.placeholder"
    :style="{ width: config.width }"
    :filter-option="false"
    :not-found-content="null"
    @popupScroll="handlePopupScroll($event)"
    @search="filterOpts"
    @focus="filterOpts"
    @change="clickOpt"
    :getPopupContainer="
      (triggerNode) => {
        return triggerNode.parentNode || document.body;
      }
    "
  >
    <a-select-option
      v-for="(item, index) in dataList"
      :value="item[config.value]"
      :key="index"
      >{{ item[config.label] }}
    </a-select-option>
  </a-select>
</template>

<script>
import axios from "@/config/http";

export default {
  name: "selectScroll", // 下拉滚动组件
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          url: "/front-api/edge/base",
          params: "edgeName",
          label: "name",
          value: "id",
          width: "200px",
          pass: true,
          placeholder: "",
        };
      },
    },
    valId: {
      // type: String,
      // default:undefined
    },
    defaultData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  model: {
    prop: "valId",
    event: "getSelectId",
  },
  data() {
    return {
      selectedVal: null, // select组件绑定的value值
      pageNum: 1, // 当前加载的页数
      pageSize: 10, // 每页加载的数据个数
      data: {}, // 服务器返回的响应信息
      dataList: [], // 服务器返回的响应信息中的搜索匹配项数据
      timer: null, // 定时器，控制请求频率
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setDefaultval();
    });
  },

  watch: {
    valId(newName) {
      this.selectedVal = newName;
    },
  },
  methods: {
    setDefaultval() {
      this.selectedVal = this.valId;
      //  this.$emit("getSelectId", undefined);
      if (this.defaultData.length > 0) {
        this.dataList = this.defaultData;
      }
    },
    // 文本框值变化时回调
    filterOpts(val) {
      this.selectedVal = val;
      if (!val) {
        this.$emit("getSelectId", null);
      }
      // this.$emit("getSelectId", val);
      clearTimeout(this.timer);
      this.dataList = [];
      this.pageNum = 1;
      this.pageSize = 10;
      this.timer = setTimeout(() => {
        this.getDataList();
      }, 500);
    },
    // 列表滚动时加载数据
    handlePopupScroll(e) {
      const {scrollTop } = e.target;
      // if (scrollHeight - scrollTop === clientHeight) {
      if (scrollTop > 74) {
        if (this.pageNum < this.data.pages) {
          this.pageNum += 1;
          this.getDataList();
        }
      }
    },
    //获取边缘服务器下拉列表
    getDataList() {
      let params = {
        // edgeName: this.edgeServe,
        page: this.pageNum,
        size: this.pageSize,
      };
      params[this.config.params] = this.selectedVal;
      axios.get(`${this.config.url}`, { params }).then((res) => {
        if (res.success) {
          this.data = res.data;
          if (this.dataList.length <= this.data.total) {
            this.dataList.push(...res.data.records);
          }
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    // 选中 option 调用
    clickOpt(val) {
      if (this.config.pass) {
        this.selectedVal = val;
        this.$emit("getSelectId", val);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>