<template>
  <div>
    <div class="search_container add_button">
      <div>
        <!-- <button @click="show = !show">Toggle render</button>
        <transition name="xxx-fade">
          <p v-if="show">hello</p>
        </transition> -->
        <span>租户编号：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="tenantCode"
        />
      </div>
      <div>
        <span>应用名称：</span>
        <SelectScroll
          @getSelectId="getSelectId($event)"
          :config="selectConfig"
          v-model="appName"
        ></SelectScroll>
      </div>
      <div>
        <span>资源类型：</span>
        <a-select v-model="resourceType" class="input_width">
          <a-select-option
            v-for="(item, index) in resourceTypeList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div style="text-align: right">
        <a-button @click="reset"> 重置 </a-button>
        <a-button
          style="margin-left: 20px"
          @click="handleSearch"
          type="primary"
        >
          查询
        </a-button>
      </div>
    </div>
    <div style="margin-top: 20px">
      <a-table
        :loading="loading"
        style="min-width: 1500px"
        :pagination="pagination"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :columns="columns"
        :data-source="dataTable"
          :scroll="{ y: 379 }"
      >
        <span slot="resourceType" slot-scope="text">
          <span v-if="text === 1">MySQL</span>
          <span v-if="text === 2">MongoDB</span>
          <span v-if="text === 3">Redis</span>
          <span v-if="text === 4">RocketMQ</span>
          <span v-if="text === 5">Nebula</span>
          <span v-if="text === 6">InfluxDB</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <a style="margin-left: 20px" @click="openModel('look', record)">
            查看密码
          </a>
        </span>
      </a-table>
    </div>
    <ModelTag
      v-if="modelConfig.show"
      @handleCancel="handleCancel"
      @handleOk="handleOk"
      :config="modelConfig"
    ></ModelTag>
  </div>
</template>

<script>
import pagination from "@/mixins/Pagination";
import ModelTag from "./model.vue";
import { sassInfoApi } from "@/api/sassInfo.js";
import SelectScroll from "../../components/selectScroll.vue";

export default {
  mixins: [pagination],
  components: {
    ModelTag,
    SelectScroll,
  },
  data() {
    return {
      show: true,
      loading: false,
      resourceType: "",
      tenantCode: "",
      appName: "",
      selectConfig: {
        url: "/cloud-platform-backend-facade/front-api/v1/tenant/base/appNameByPage",
        params: "appName",
        label: "appName",
        value: "appName",
        width: "240px",
        pass: true,
        password: "",
      },
      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "",
        width: "60%",
        okText: "保存",
        delArr: [],
        password:''
      },

      columns: [
        {
          title: "租户编号",
          dataIndex: "tenantCode",
          key: "tenantCode",
          align: "center",
        },
        {
          title: "应用名称",
          dataIndex: "appName",
          key: "appName",
          align: "center",
        },
        {
          title: "资源类型",
          dataIndex: "resourceType",
          key: "resourceType",
          align: "center",
          scopedSlots: { customRender: "resourceType" },
        },
        {
          title: "数据库名",
          dataIndex: "appIdentity",
          key: "appIdentity",
          align: "center",
        },
        {
          title: "连接地址",
          dataIndex: "address",
          key: "address ",
          align: "center",
        },
        {
          title: "端口",
          dataIndex: "portNum",
          key: "portNum",
          align: "center",
        },
        {
          title: "用户名",
          dataIndex: "username",
          key: "username",
          align: "center",
        },
        {
          title: "密码",
          align: "center",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      resourceTypeList: [
        {
          name: "MySQL",
          value: 1,
        },
        {
          name: "MongoDB",
          value: 2,
        },
        {
          name: "Redis",
          value: 3,
        },
        {
          name: "InfluxDB",
          value: 4,
        },
        {
          name: "Nebula",
          value: 5,
        },
        {
          name: "RocketMQ",
          value: 6,
        },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    //重置
    reset() {
      this.appName = "";
      this.resourceType = "";
      this.tenantCode = "";
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData();
    },
    //查询数据接口
    getData() {
      this.loading = true;
      let params = {
        appName: this.appName,
        resourceType: this.resourceType,
        tenantCode: this.tenantCode,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      sassInfoApi.getTableData(params).then((res) => {
        if (res.success) {
          this.loading = false;
          this.pagination.total = Number(res.data.total);
          this.dataTable = res.data.records;
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //查询
    handleSearch() {
      this.pagination.current = 1;
      this.getData();
    },

    //调用弹框事件
    async openModel(type, record) {
      this.modelConfig.id = record ? record.id : null;
      this.modelConfig.type = type;
      if (type === "look") {
        this.modelConfig.record = record;
        this.modelConfig.title = "查看密码";
        this.modelConfig.okText = "确定";
        this.modelConfig.width = "600px";
        this.modelConfig.show = true;
      } else {
       this.modelConfig.password = record;
        this.modelConfig.title = "查看密码";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
        this.modelConfig.show = true;
      }
    },
    async getPassword() {
      this.confirmLoading = true;
      let id = this.modelConfig.record.resourceId;
      await sassInfoApi.getPassword(id).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.modelConfig.password = res.data;
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //弹框关闭
    handleCancel() {
      this.modelConfig.show = false;
    },
    //弹框保存按钮
    handleOk(val) {
      this.modelConfig.show = false;
      if (this.modelConfig.type === "look") {
        this.openModel("password", val);
      }
      this.getData();
    },
    // 分页改变
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getData();
    },
    //分页
    onPage(current) {
      this.pagination.current = current;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  width: 200px;
  background-color: greenyellow;
  // opacity: 1;
}
.xxx-fade-enter-active,
.xxx-fade-leave-active {
  transition: opacity 1s;
}
//  {
//   transition: opacity 1s;
// }
.xxx-fade-enter, .xxx-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
.dateStyle {
  color: red;
}
.input_width {
  width: 240px;
}
.blank_contain {
  width: 340px;
}
.search_container {
  display: flex;
  justify-content: space-between;
  min-width: 1500px;
  overflow: auto;
}
.add_button {
  // margin-top: 20px;
  margin-bottom: 20px;
}
.popover_contain {
  height: 30px;
  width: 120px;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.popover_contain:hover {
  background-color: #f3f3f3;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.modal_reset {
  color: rgb(136, 131, 131);
  font-size: 14px;
  padding-left: 36px;
  font-weight: 200;
}
/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
  /deep/.ant-table {
    min-height: 379px;
    }
</style>
<style>
</style>